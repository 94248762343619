import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { getItemDataQuery } from '../../queries';
import BinaModal from '../ReactModal';
import { selectPromotionModalViewState } from '../../store/selectors';
import Promotion from '../Ad/Promotion';
import BumpIcon from '../../public/static/images/item/promotion.svg';
import VipIcon from '../../public/static/images/item/vip.svg';
import FeatureIcon from '../../public/static/images/item/feature.svg';
import useTogglePromotionModalView from '../../hooks/useTogglePromotionModalView';
import { formatNumberWithComma } from '../../utils/helpers';

const PromotionModalView = () => {
  const router = useRouter();
  const { id } = router.query;
  const { t } = useTranslation();
  const {
    onCloseHandler,
    splitPath,
    isRestoreView,
    isPremiumView,
    isPaymentMethodView,
    isPersonalAdsLimitView
  } = useTogglePromotionModalView();
  const { isVisible: isVisiblePromotionModalView } = useSelector(
    selectPromotionModalViewState
  );
  const { data } = useQuery(getItemDataQuery(id), {
    variables: {
      id
    },
    ssr: false
  });
  const getIcon = serviceId => {
    switch (true) {
      case serviceId === 'bump':
        return BumpIcon;
      case serviceId === 'vip':
        return VipIcon;
      case serviceId === 'feature':
        return FeatureIcon;
      default:
        return null;
    }
  };
  const getMessage = serviceId => {
    switch (true) {
      case serviceId === 'balance_paid_ad':
        return t('pages.current_item.promotion.make_restore_info');
      case serviceId === 'bump':
        return t('pages.current_item.promotion.raise_ad_info');
      case serviceId === 'vip':
        return t('pages.current_item.promotion.make_vip_info');
      case serviceId === 'feature':
        return t('pages.current_item.promotion.make_premium_info');
      case serviceId === 'deposit':
        return t('pages.current_item.promotion.free_limit_per_month');
      default:
        return '';
    }
  };

  const getPromotionModalTitle = title => {
    switch (true) {
      case isRestoreView:
        return t('pages.current_item.promotion.make_restore');
      case isPaymentMethodView:
        return t('pages.current_item.promotion.ads_payment');
      default:
        return title;
    }
  };
  const getContent = service => {
    if (!service) {
      return {};
    }
    return {
      name: service.id,
      icon: getIcon(service.id),
      title: getPromotionModalTitle(service.title),
      targetType: service.targetType,
      isPayed: !!service.activeUntil,
      activeUntil: service.activeUntil,
      message: getMessage(service.id),
      additionalMessage: t('pages.current_item.promotion.send_for_review'),
      serviceTermArray: service.options.map(option => {
        return {
          id: option.id,
          name: `${option.name} / ${formatNumberWithComma(option.price)} AZN`,
          price: option.price
        };
      }),
      serviceTermArrayWithDiscountPrice: service.options.map(option => {
        return {
          id: option.id,
          name: `${option.name} / ${formatNumberWithComma(
            option.priceWithDiscount
          )} AZN`,
          price: option.priceWithDiscount
        };
      }),
      paymentList: service.paymentMethods.map(method => {
        if (method.id === 'wallet') {
          return {
            id: method.id,
            name: t('pages.current_item.promotion.personal_account', {
              amount: formatNumberWithComma(method.balance)
            }),
            isShow: true,
            balance: method.balance
          };
        }
        return {
          id: method.id,
          name: method.name,
          isShow: true
        };
      })
    };
  };

  const promotionViewContent = {
    service_bump: getContent(
      data?.item?.paidServices.filter(service => service.id === 'bump')[0]
    ),
    service_vip: getContent(
      data?.item?.paidServices.filter(service => service.id === 'vip')[0]
    ),
    service_feature: getContent(
      data?.item?.paidServices.filter(service => service.id === 'feature')[0]
    ),
    restore: getContent(
      data?.item?.paidServices.filter(
        service => service.id === 'balance_paid_ad'
      )[0]
    ),
    limit_payment: getContent(
      data?.item?.paidServices.filter(
        service => service.id === 'balance_paid_ad'
      )[0]
    ),
    personal_ads: {
      icon: null,
      title: t('pages.current_item.promotion.make_restore'),
      message: t('pages.current_item.promotion.free_limit_per_month'),
      additionalMessage: t('pages.current_item.promotion.send_for_review'),
      serviceTermArray: [],
      paymentList: []
    }
  };

  const content = promotionViewContent[splitPath[1]];

  return (
    <BinaModal
      modalId={'promotion-view'}
      isOpen={isVisiblePromotionModalView}
      overlayClassName="modal__overlay"
      contentClassName="modal__content--side-menu"
    >
      <div
        className="modal__content--side-menu-container"
        data-cy="promotion-view-container"
      >
        <Promotion
          {...{
            onCloseHandler,
            content,
            isPremiumView,
            isRestoreView,
            isPaymentMethodView,
            isPersonalAdsLimitView
          }}
        />
      </div>
    </BinaModal>
  );
};

export default PromotionModalView;
